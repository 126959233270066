import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from '@remix-run/react'
import { type ReactNode } from 'react'

type FAQ = {
	question: string
	answer: ReactNode
}

const defaultFaqs = [
	{
		question:
			'I filed with Taxxwiz last year, how do I file my taxes with you again?',
		answer: (
			<p>
				Just fill out the form below and make sure to check the box saying you
				have filed with us before.
			</p>
		),
	},
	{
		question: 'Does the software file taxes?',
		answer: (
			<p>
				Contact{' '}
				<Link to="mailto:jrob@smartwiz.io" reloadDocument>
					jrob@smartwiz.io
				</Link>{' '}
				for information on last years tax return{' '}
			</p>
		),
	},
	{
		question: 'How long does it take to get my taxes done?',
		answer: (
			<p>
				We will have your taxes filed to the IRS within 72 hours or receiving
				all of the required information.
			</p>
		),
	},
]

export default function Faq({ faqs = defaultFaqs }: { faqs?: FAQ[] }) {
	return (
		<>
			<h2 className="text-center font-display text-3xl font-bold tracking-tight sm:text-4xl">
				Frequently Asked Questions
			</h2>
			<div className="mx-auto mt-8 max-w-4xl rounded-lg border border-gray-900">
				{faqs.map(({ question, answer }) => (
					<Disclosure
						key={question}
						as="div"
						className="w-full border-b border-gray-900 last:border-0"
					>
						<Disclosure.Button className="flex w-full items-center justify-between px-6 py-3 text-left font-display font-bold md:text-lg">
							{question}
							<ChevronRightIcon className="h-5 w-5" />
						</Disclosure.Button>
						<Disclosure.Panel className="px-6 pb-6 text-gray-900/75">
							{answer}
						</Disclosure.Panel>
					</Disclosure>
				))}
			</div>
		</>
	)
}
